.container-dropzone {
  text-align: center;
  padding: 25px;
  background-color: rgba(255, 255, 255, .1);
  border: 3px white dashed;
  margin: auto;
}

.text__center {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.2rem;
  align-items: center;
}

.text__center::before,
.text__center::after {
  content: "";
  display: block;
  height: 1px;
  background-color: currentColor;
}