/* Conteneur des blocs de contenu */
.about-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

/* Titre de la page */
.about-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #4f46e5;
}

/* Conteneur du texte et des images */
.about-content {
    display: grid;
    justify-items: center;
    margin-top: 50px;
}

/* Bloc de texte */
.about-text {
    max-width: 700px;
    text-align: justify;
}

/* Blocs d'images avec animation */
.about-image {
    position: relative;
    overflow: hidden;
    max-width: 400px;
    height: 300px;
    margin-bottom: 20px;
    border: 2px solid #ddd;
    border-radius: 8px;
    background-size: cover;
    background-position: center center;
    animation: fade-in-out 10s ease infinite;
}

.about-image:hover {
    animation-play-state: paused;
}

@keyframes fade-in-out {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}