.navbar {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  align-items: center;
}

.logo {
  padding-right: 20px;
}

.links {
  display: flex;
  gap: 10px;
}

.nav-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 25px;
  height: 100%;
}

.nav-link {
  display: flex;
  font-size: medium;
  align-items: center;
  font-weight: 500;
  padding: 10px 17.5px;
  opacity: 70%;
}

.nav-link:hover {
  opacity: 1;
}

.active {
  opacity: 1;
  color: black;
}

.nav-pages {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.navbar > .nav-toggle {
  display: none;
}

.profil-web {
  display: block;
}

.profil-phone {
  display: none;
}

@media (max-width: 786px) {
  .navbar > .nav-items {
    position: absolute;
    z-index: 10;
    top: 0px;
    display: grid;
    justify-items: center;
    align-items: none;
    background-color: rgba(255, 255, 255, 0.9);
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 25px 10px;
    transform: translateY(-130%);
    transition: all 0.45s;
  }

  .profil-web {
    display: none;
  }
  .profil-phone {
    display: block;
  }

  .links {
    padding: 25px;
    display: block;
  }

  .nav-pages {
    flex-direction: column;
    gap: 25px;
    padding-bottom: 20px;
  }

  .nav-signin {
    justify-self: center;
  }

  .navbar > .nav-items > a::before {
    background: transparent;
  }

  .navbar > .nav-items.open {
    transform: translateX(0);
  }

  .navbar > .nav-toggle {
    justify-self: end;
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle > .bar {
    position: relative;
    width: 24px;
    height: 1px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 3px;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-6px);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 24px;
    transform: translateY(6px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 24px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}
