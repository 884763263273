.bg {
  overflow: hidden;
  animation: bg 10s infinite;
}
@keyframes bg {
  0%,
  100% {
    background-color: rgb(60, 73, 155, .5);
  }
  50% {
    background-color: rgb(60, 73, 155, .75);
  }
}

.arrow {
  animation: arrow 2.5s infinite;
}
@keyframes arrow {
  0%,
  100% {
    opacity: .25;
  }
  50% {
    opacity: 1;
  }
}


.gradiant {
  overflow: hidden;
  background: radial-gradient(circle at bottom, #4B65F1, transparent 80%), linear-gradient(to top, #0827CB 0%, transparent 70%), linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.4) 70%), #1e90ff;
  animation: gradiant 15s infinite;
  background-attachment: fixed;
}
@keyframes gradiant {
  0%, 100% {
    background-color: #541C8C;
 }
  25% {
    background-color: #2E0E59;
 }
  85% {
    background-color: #13002E;
 }
}